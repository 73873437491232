<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Autorizaciones para Directivos</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="facturas" :select="seleccionar" :filters="filtros" @actions="dataGridActions" @ready="buscar_botones">
			<div class="col-md-2 btn-recargar">
				<button class="btn warning-btn" @click="cargar_facturas">Recargar</button>
			</div>
			<div class="col-md-2 btn-revisar">
				<button class="btn secondary-btn" @click="revisar_factura">Revisar factura</button>
			</div>
			<div class="col-md-2 btn-ayuda">
				<div class="dropdown v-step-bt-4">
				  <button class="btn complementary-principal-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Ayuda
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'interface')">Interface</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'detalles')">Ver detalles de facturas</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'filtros')">Filtros</a>
				  </div>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="factura" :options="{width: '80vw',close:true}" @close="cancelar_revisar_factura" class="bpb-modal">
			<h2 class="title">Factura</h2>
			<div class="body">
				<FacturaView :factura="factura" @observaciones="agregar_observacion"/>
			</div>
			<div v-if="verificar_estatus" class="footer">
				<div class="row">
					<div v-if="ver_botones" class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-2">
							<button class="btn principal-btn btn-aprobar" @click="actualizar_factura(5)" v-if="check_permisos">Aprobar</button>
						</div>
						<div  class="col-md-2">
							<button class="btn warning-btn btn-cancelar" @click="actualizar_factura(0)" v-if="check_permisos">Rechazar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="rechazar" class="bpb-modal" @close="rechazar=false">
			<h2 class="title">Rechazar factura</h2>
			<div class="body">
			    <div class="row">
			        <label for="razon_rechazo" class="col-form-label col-sm-2">Razón del rechazo</label>
			        <div class="col-sm-10">
			            <textarea v-model="observacion" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control razon_rechazo"></textarea>
			        </div>
			    </div>
			    <br />
			    <div>
			        <div class="row">
			            <div class="col-sm-12 d-flex justify-content-end">
			                <div class="col-md-3">
			                    <button class="btn warning-btn" @click="rechazar_factura">Rechazar</button>
			                </div>
			                <div class="col-md-3">
			                    <button class="btn complementary-btn" @click="rechazar=false">Cancelar</button>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
		</Modal>

		<v-tour name="tour-interface" :steps="steps_inteface" :options="steps_options" :callbacks="steps_callbacks_interface"></v-tour>
		<v-tour name="tour-detalles" :steps="filtros_detalles_steps" :options="steps_options" :callbacks="steps_callbacks_detalles"></v-tour>
		<v-tour name="tour-filtros" :steps="steps_filtros" :options="steps_options" :callbacks="steps_callbacks_filtros"></v-tour>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import FacturaView from '@/apps/tesoreria/components/FacturasView'
	import {VMoney} from 'v-money'

	import account from '@/api/account'
	import api from '@/apps/tesoreria/api/tesoreria'

	export default {
		components: {
			DataGrid, Modal, VMoney, FacturaView
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					empresa_id: 'Proveedor'
					,proveedor_id: 'Representante'
					,total: 'Total'
					,subtotal: 'Subtotal'
					,created_at: 'Fecha de subida'
					,metodo_pago: 'Método Pago'
					,estatus: 'Estatus'
					,id: {
						text: 'Documentos'
						,ordeable: false
					}
				}
				,filters: {
					cols: {
						id: 'ID'
						,empresa_id: {
							text: 'Proveedor'
							,options: {}
						}
						,proveedor_id: {
							text: 'Representante'
							,options: {}
						}
						,total: 'Total'
						,subtotal: 'Subtotal'
						,metodo_pago: 'Método Pago'
						,estatus: {
							text: 'Estatus'
							,options: {
								0: 'Rechazada'
								,1: 'Sin revisar'
								,2: 'Aceptada sin revisión'
								,3: 'En revisión por director de Finanzas'
								,4: 'En revisión por director de Finanzas y director General'
								,5: 'En proceso de pago'
								,6: 'Pagada'
							}
						}
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					empresa_id: function(val, row) {
						return row.empresa ? row.empresa.empresa : 'Sin especificar';
					}
					,proveedor_id: function(val, row) {
						return row.proveedor_nombre;
					}
					,total: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,subtotal: function(val,row,vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,proveedor: function(val) {
						return val.nombre;
					}
					,estatus: function(val, row, dataGrid, vuex) {
						let buscar_estatus = function(vuex, estatus) {
							let nombre = '';
							// console.log('vuex', vuex);
							if (vuex.factura_status)
								for(let i=0; i<vuex.factura_status.length; i++) {
									if (vuex.factura_status[i].llave == 'catalogo_estatus' && parseInt(vuex.factura_status[i].valor) == estatus) {
										nombre = vuex.factura_status[i].nombre;
										i=vuex.factura_status.length;
									}
								}

							return nombre;
						}

						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">'+buscar_estatus(vuex, 0)+'</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">'+buscar_estatus(vuex, 1)+'</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">'+buscar_estatus(vuex, 2)+'</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">'+buscar_estatus(vuex, 3)+'</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 4)+'</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 5)+'</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+buscar_estatus(vuex, 6)+'</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row, vue) {
						return '<button class="btn principal-btn" name="download_files" value="'+val+'" data-json="'+encodeURI((JSON.stringify(row)))+'">Descargar</button>';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
			,factura: null
			,seleccionadas: []
			,seleccionar: false
			,observacion: null
			,rechazar: false
			,pagar: false
			,pago: {
				banco: null
				,cuenta: null
				,monto: null
			}
			,proveedores: []
			,filtros: [
			{
					relacion: 'or'
					,columna: 'estatus'
					,operador: 'equal_to'
					,valor1: '3'
					,valor2: null
				},
				{
					relacion: 'or'
					,columna: 'estatus'
					,operador: 'equal_to'
					,valor1: '4'
					,valor2: null
				}
			]
			,steps_options: {
				labels: {
					buttonSkip: 'Finalizar'
					,buttonPrevious: 'Anterior'
					,buttonNext: 'Siguiente'
					,buttonStop: 'Finalizar'
				}
			}
			,step_inteface: 0
			,steps_callbacks_interface: {}
			,step_interface: 0
			,steps_inteface: [
				{
					target: '.btn-recargar'
					,content: 'Permite recargar el contenido manteniendo la configuración actual (filtros, paginado, orden, etc)'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: '.btn-ayuda'
					,content: 'Muestra información del dashboard y lista las guías de la aplicación'
				}
				,{
					target: 'table.dataGrid-data'
					,content: 'Grid donde se muestra y ordena la información'
				}
				,{
					target: 'button[name="descargar_documentos"]'
					,content: 'Permite descargar los documentos de la factura indicada'
				}
			]
			,steps_callbacks_detalles: {}
			,step_detalles: 0
			,filtros_detalles_steps: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Seleccionamos una factura'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: 'label[for="proveedor"]'
					,content: 'Se visualiza información más detallada de la factura'
				}
				,{
					target: 'label[for="conceptos"]'
					,content: 'Listamos todos los conceptos de la factura'
				}
				,{
					target: 'label[for="impuestos"]'
					,content: 'Listamos todos los impuestos de la factura'
				}
				,{
					target: 'label[for="pagos"]'
					,content: 'Cuando la factura ya tiene pagos se listarán aquí'
				}
				,{
					target: 'label[for="observaciones"]'
					,content: 'Se listan las observaciones que se van agregando según el proceso que lleve la factura, también podrás ver observaciones que agreguen los operadores de BienParaBien'
				}
				,{
					target: '.agregar_observacion_btn'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
				,{
					target: '.btn-aprobar'
					,content: 'Podemos aprobar la factura para que pase a su siguiente fase que puede ser "En proceso de pago", "En revisión por director de Finanzas" y "En revisión por director de General" dependiendo del monto de la factura'
				}
				,{
					target: '.btn-rechazar'
					,content: 'Si crees que la factura tiene un error o es incorrecta en cualquier sentido siempre podrás rechazarla, solo toma en cuenta que esta acción no se puede revertir'
				}
				,{
					target: '.btn-cancelar'
					,content: 'Para cerrar la ventana sin modificar la factura puedes dar click en el botón "Cancelar"'
				}
			]
			,steps_callbacks_filtros: {}
			,step_filtros: 0
			,steps_filtros: [
				{
					target: '#dataGrid-btn-add-filter'
					,content: 'Los filtros funcionan para limitar los registros a visualizar, especialmente cuando se busca un registro o registros en particular, para agregar un filtro comenzamos dando click al botón "Agregar filtro"'
				}
				,{
					target: '#filtro_columna'
					,content: 'Seleccionamos la columna a la que se le aplicará el filtro'
				}
				,{
					target: '#filtro_operador'
					,content: 'Seleccionamos el operador que se aplicará al filtro'
				}
				,{
					target: '#valor1'
					,content: 'Indicamos el valor que tendrá el filtro, esto podría ser unicamente texto o en caso de que la columna sea de un tipo en especifico deberá tener un formato en especial, por ejemplo, para fechas el formato tendrá que ser YYYY-MM-DD HH:ii:ss, puede ser solo la fecha, la fecha y hora o solo la hora, algunos operadores pueden requerir más de un dato'
				}
				,{
					target: '.dataGrid-filter-btn-add'
					,content: 'Finalmente damos click en el botón agregar o cancelar en caso que ya no desees agregar filtros'
				}
			]
		})
		,created: function() {
			// this.filtros[0].valor1 == this.$auth.getUser().usuario == 'amolina' ? 3 : 4;
		}
		,mounted: function() {

			// this.cargar_facturas();
			this.cargar_estatus();
			this.cargar_proveedores();
			this.cargar_empresas();

			this.steps_callbacks_interface = {
				onStart: this.tour_interface_start
			}

			this.steps_callbacks_detalles = {
				onStart: this.tour_detalles_next
				,onNextStep: this.tour_detalles_next
				,onPreviousStep: this.tour_detalles_prev
				,onStop: this.tour_detalles_stop
			}

			this.steps_callbacks_filtros = {
				onNextStep: this.tour_filtros
				,onPreviousStep: this.tour_filtros_prev
				,onStop: this.tour_filtros_stop
			}
		}
		,methods: {
			cargar_facturas() {
				api.obtener_facturas(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error al obtener facturas');
				})
			}
			,cargar_proveedores: async function() {
				this.proveedores = (await api.obtener_proveedores()).data;

				this.proveedores.some((proveedor) => {
					this.dataGridConfig.filters.cols.proveedor_id.options[proveedor.id] = proveedor.nombre+' ('+proveedor.empresa.empresa+')';
				});
			}
			,cargar_empresas: async function() {
				let empresas = (await api.listar_empresas()).data;

				empresas.some((empresa) => {
					this.dataGridConfig.filters.cols.empresa_id.options[empresa.id] = empresa.empresa;
				});
			}
			,dataGridActions: function(tipo, data) {
				// this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.cargar_facturas();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,buscar_botones: function() {
				this.$log.info('buscando botones');
				document.querySelectorAll('button[name=download_files]').forEach(btn => {
					// this.$log.info('btn', btn);
					btn.removeEventListener('click',this.descargar_archivos);
					btn.addEventListener('click', this.descargar_archivos);
				});
			}
			,descargar_archivos: async function(e) {
				let factura = JSON.parse(decodeURI(e.target.getAttribute('data-json')));

				try {
					let res = (await api.descargar_documentos_v2(factura.id)).data;
					let domain = process.env.VUE_APP_API_TESORERIA;
          domain = domain.replace('api/v1','storage');
          window.open(domain+'/'+res.file);
				}catch (e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,revisar_factura: function() {
				if (this.seleccionadas.length > 0) {
					this.factura = this.seleccionadas[0];
					this.$log.info('factura', this.factura);
					this.$log.info('estatus', this.factura.estatus != 6);
				}else {
					this.$helper.showAxiosError('Tienes que seleccionar una factura', 'Error');
				}
			}
			,cancelar_revisar_factura: function() {
				this.factura = null;
				this.seleccionar = false;
			}
			,actualizar_factura: async function(estatus) {
				this.$log.info('estatus', estatus);

				if (this.step_detalles == 0) {
					if (estatus > 0) {
						let estatusOrg = this.factura.estatus;
						// this.factura.estatus = estatus;
						// this.factura.total = 180014.52;

						if (estatusOrg == 3) {
							let config = await api.cargar_estatus();

							for(let i=0; i<config.data.length; i++) {
								if (config.data[i].llave == 'montos_validaciones') {
									try {
										let json = JSON.parse(config.data[i].valor);

										if (parseFloat(this.factura.total) > parseFloat(json.monto)) {
											this.factura.estatus = json.estatus;
										}
									}catch(e) {
										this.$log.info('error', e.message);
									}
								}
							}

							this.$log.info('factura', this.factura);
						}

						if (this.factura.total )
						this.factura.estatus = estatus;

						api.actualizar_factura(this.factura.id, this.factura)
						.then(res => {
							this.$log.info('res', res);
							this.factura = null;
							this.seleccionar = false;
							this.cargar_facturas();
						})
						.catch(err => {
							this.$log.error('err', err);

							this.$helper.showAxiosError(err, 'Error al actualizar factura');
						})
					}else {
						this.rechazar = true;
					}
				}
			}
			,agregar_observacion: function() {
				if (this.observacion && this.observacion.length > 5) {
					api.enviar_observaciones_factura(this.factura.id, {observacion: this.observacion})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones = res.data;
						this.observacion = null;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,rechazar_factura: async function() {
				if (this.observacion && this.observacion.length > 5) {
					await this.agregar_observacion();

					this.factura.estatus = 0;
					await api.actualizar_factura(this.factura.id, this.factura)
					.then(res => {
						this.$log.info('res', res);

						this.rechazar = false;
						this.observacion = null;
						this.cancelar_revisar_factura();
						this.cargar_facturas();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err, 'Error al rechazar factura');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,cargar_estatus: function() {
				if (!this.$store.state.factura_status)
					api.cargar_estatus()
					.then(res => {
						this.$log.info('res', res);
						this.$store.commit('set', {factura_status: res.data});
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
			}
			,iniciar_tour: function(e, tipo) {
				e.preventDefault();

				switch(tipo) {
					case 'interface':
						this.$tours['tour-interface'].start();
						break;
					case 'detalles':
						if (this.facturas.length == 0) {
							this.$helper.showMessage('Error','Para iniciar esta guía es necesario tener al menos una factura cargada','error','alert');
							break;
						}
						this.$tours['tour-detalles'].start();
						break;
					case 'filtros':
						this.$tours['tour-filtros'].start();
						break;
				}
			}
			,tour_interface_start: function() {
				this.$log.info('guia facturas', this.facturas.length);

				if (this.facturas.length == 0)
					this.steps_inteface = [
						{
							target: '.btn-recargar'
							,content: 'Permite recargar el contenido manteniendo la configuración actual (filtros, paginado, orden, etc)'
						}
						,{
							target: '.btn-revisar'
							,content: 'Muestra información detallada de la factura seleccionada'
						}
						,{
							target: '.btn-ayuda'
							,content: 'Muestra información del dashboard y lista las guías de la aplicación'
						}
						,{
							target: 'table.dataGrid-data'
							,content: 'Grid donde se muestra y ordena la información'
						}
					];
			}
			,tour_filtros: function(paso) {
				this.$log.info('step filtros', paso);
				this.step_filtros = paso;

				if (paso == 0)
					document.getElementById('dataGrid-btn-add-filter').click();

				if (paso >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(paso) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_prev: function() {
				this.step_filtros -= 1;
				this.$log.info('step filtros prev', this.step_filtros);

				if (this.step_filtros >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(this.step_filtros) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_stop: function() {
				document.querySelector('button.dataGrid-filter-btn-cancel').click();
				this.step_filtros = 0;
			}
			,tour_detalles_next: function(step) {
				this.$log.info('step',step)
				this.step_detalles = step;

				if (typeof(step) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();

				if (step == 1)
					this.revisar();
			}
			,tour_detalles_prev: function(step) {
				this.step_detalles -= 1;
				this.$log.info('step',this.step_detalles)

				if (this.step_detalles == 0) {
					this.cancelar_revisar_factura();
					setTimeout(() => {
						document.querySelector('input[type="checkbox"][name="facturas"]').click();
					},20);
				}
			}
			,tour_detalles_stop: function() {
				this.step_detalles = 0;
				this.cancelar_revisar_factura();
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.factura.total
			}
			,subtotal_procesada: function() {
				return '$'+this.factura.subtotal
			}
			,check_permisos: function() {
				let permiso;

				if (this.factura.estatus < 6)
					permiso = this.$auth.can('tesoreria','tesoreria_directivos');
				else
					permiso = false;

				this.$log.info('permiso: ', (permiso ? 'es general' : 'no es general'));

				return permiso;
			}
			,obtener_metodo_pago: function() {
				let metodo = '';

				switch(this.factura.metodo_pago) {
					case 'PUE':
						metodo = 'Pago en una sola exhibición (PUE)';
						break;
					case 'PPD':
						metodo = 'Pago en parcialidades o diferido (PPD)';
						break;
				}

				return metodo;
			}
			,verificar_estatus: function() {
				this.$log.info('estatus', this.factura.estatus);
				this.$log.info('Es valido: ', this.factura.estatus != 6)

				return this.factura.estatus != 6;
			}
			,ver_botones: function() {
				let usuario = this.$auth.getUser().usuario;
				if (usuario == 'amolina')
					return this.factura.estatus <= 3 || this.factura.estatus == 5;
				else
					return true;
			}
		}
	}
</script>

<style lang="scss" scope>
	.observaciones {
		max-height: 200px;
		overflow: auto;

		.row {
			margin-right: 3px;
			padding: 3px;

			span.nombre {
				font-weight: bold;
			}

			span.fecha {
				font-size: 0.6em;
				vertical-align: middle;
			}
		}

		.row:nth-child(2n+0) {
			background-color: #E6E6E6;
		}
	}

	.razon_rechazo {
		width: 100%;
		height: 170px !important;
		resize: none;
	}
</style>