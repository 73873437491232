import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class Tesoreria
{
	obtener_facturas(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/facturas', options));
	}

	obtener_facturas_invalidas(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/facturas/invalidas', options));
	}

	actualizar_factura(id, payload) {
		return axios.put(baseURL+'/tesoreria/facturas/'+id, payload);
	}

	enviar_observaciones_factura(id, payload) {
		return axios.post(baseURL+'/tesoreria/facturas/'+id+'/observaciones', payload);
	}

	descargar_documentos(factura, nombre) {
		axios({
			url: baseURL+'/tesoreria/facturas/'+factura+'/documentos'
			,method: 'GET',
			responseType: 'blob'
		})
		.then(res => {
			this.procesar_descarga_documentos(res, nombre)
		})
		.catch(err => {
			if (err.response && err.response.status == 401) {
				let token = store.state.auth.refresh;
				return axios.post(process.env.VUE_APP_API_ACCOUNT+'/auth/refresh', {token})
				.then(res => {
					if (res.data.token) {
						store.commit('refreshCredentials', res.data);

						err.config.headers.Authorization = 'Bearer '+res.data.token;

						Vue.prototype.$log.info('Volvemos a enviar el request para descargar los documentos');
						return axios.request(err.config)
						.then(res => {
							this.procesar_descarga_documentos(res, nombre)
						});
					}else {
						return Promise.reject(err);
					}
				})
			}

			Vue.prototype.$log.info('err', err);

			Vue.prototype.$notify({
				group: 'alert'
				,type: 'error'
				,title: 'Error en la descargar de archivos'
				,text: 'No se pudo descargar los archivos, por favor, contacte con el administrador'
				,duration: 5000
			});
		})
	}

	descargar_documentos_v2(factura_id) {
		return axios.get(`${baseURL}/tesoreria/facturas/${factura_id}/documentos`);
	}

	descargar_documentos_invalidos(factura, nombre) {
		axios({
			url: baseURL+'/tesoreria/facturas/'+factura+'/documentos_invalidos'
			,method: 'GET',
			responseType: 'blob'
		})
		.then(res => {
			this.procesar_descarga_documentos(res, nombre)
		})
		.catch(err => {
			Vue.prototype.$log.info('err', err);

			Vue.prototype.$notify({
				group: 'alert'
				,type: 'error'
				,title: 'Error en la descargar de archivos'
				,text: 'No se pudo descargar los archivos, por favor, contacte con el administrador'
				,duration: 5000
			});
		})
	}

	procesar_descarga_documentos(res, nombre) {
		Vue.prototype.$log.info('res', res);

		const urlFile = window.URL.createObjectURL(new Blob([res.data],{type: 'application/zip'}));
		const link = document.createElement('a');
		link.href = urlFile;
		link.setAttribute('download',  nombre+'.zip');
		document.body.appendChild(link);
		link.click();
	}

	pagar_factura(id, payload) {
		return axios.post(baseURL+'/tesoreria/facturas/'+id+'/pagar', payload);
	}

	obtener_proveedores(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/proveedores', options));
	}

	crear_proveedor(payload) {
		return axios.post(baseURL+'/tesoreria/proveedores', payload);
	}

	editar_proveedor(id, payload) {
		return axios.put(baseURL+'/tesoreria/proveedores/'+id, payload);
	}

	obtener_aprobadores(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/autorizadores', options));
	}

	obtener_usuarios() {
		return axios.get(baseURL+'/tesoreria/autorizadores/usuarios');
	}

	crear_autorizador(payload) {
		return axios.post(baseURL+'/tesoreria/autorizadores', payload);
	}

	editar_autorizador(id, payload) {
		return axios.put(baseURL+'/tesoreria/autorizadores/'+id, payload);
	}

	eliminar_autorizador(id) {
		return axios.delete(baseURL+'/tesoreria/autorizadores/'+id);
	}

	asignar_proveedor_autorizador(id, payload) {
		return axios.put(baseURL+'/tesoreria/autorizadores/'+id+'/proveedores', payload);
	}

	desasignar_proveedor_autorizador(id, payload) {
		return axios.delete(baseURL+'/tesoreria/autorizadores/'+id+'/proveedores', {data: payload});
	}

	listar_empresas(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/empresas', options));
	}

	crear_empresa(payload) {
		return axios.post(baseURL+'/tesoreria/empresas', payload);
	}

	editar_empresa(id, payload) {
		return axios.put(baseURL+'/tesoreria/empresas/'+id, payload);
	}

	eliminar_empresa(id) {
		return axios.delete(baseURL+'/tesoreria/empresas/'+id);
	}

	obtener_facturas_autorizador(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/autorizacion', options));
	}

	cargar_factura(id, payload) {
		return axios.post(baseURL+'/tesoreria/autorizacion/facturas', payload);
	}

	obtener_facturas_directivos(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/autorizacion', options));
	}

	obtener_configuraciones(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/configuracion',options));
	}

	crear_configuracion(payload) {
		return axios.post(baseURL+'/tesoreria/configuracion',payload);
	}

	actualizar_configuracion(id, payload) {
		return axios.put(baseURL+'/tesoreria/configuracion/'+id,payload);
	}

	eliminar_configuracion(id) {
		return axios.delete(baseURL+'/tesoreria/configuracion/'+id);
	}

	cargar_estatus() {
		return axios.get(baseURL+'/tesoreria/configuracion');
	}

	obtener_preautorizadores() {
		return axios.get(baseURL+'/tesoreria/preautorizadores/all');
	}

	asignar_preautorizador(autorizadorID, payload) {
		return axios.put(baseURL+'/tesoreria/preautorizadores/'+autorizadorID+'/asignar', payload);
	}

	desasignar_preautorizador(autorizadorID, preautorizadorID) {
		return axios.delete(baseURL+'/tesoreria/preautorizadores/'+autorizadorID+'/desasignar/'+preautorizadorID);
	}

	obtener_preautorizadores_facturas(options) {
		return axios.get(utils.url_options(baseURL+'/tesoreria/preautorizadores', options));
	}

	actualizar_revision_factura(id, revision) {
		return axios.put(baseURL+'/tesoreria/preautorizadores/factura/'+id+'/revisar/'+revision);
	}

	modificar_autorizador_factura(id, idAutorizador) {
		return axios.put(baseURL+'/tesoreria/facturas/'+id+'/cambiar_autorizador/'+idAutorizador);
	}

	descarga_masiva(ids) {
		return axios.post(baseURL+'/tesoreria/facturas/descargas_masivas', {ids});
	}
}

export default new Tesoreria()