import store from '@/store'
import axios from '@/plugins/axios'

const baseURL = process.env.VUE_APP_API_ACCOUNT;

class Account
{
	login(payload) {
		return axios.post(baseURL+'/auth/login', payload);
	}

	recuperar_password(payload) {
		return axios.post(baseURL+'/auth/recovery', payload);
	}

	recuperar_password_confirm(payload) {
		return axios.put(baseURL+'/auth/recovery', payload);
	}

	cambiar_password(payload) {
		return axios.put(baseURL+'/mi-cuenta', payload);
	}

	obtener_usuarios() {
		return axios.get(baseURL+'/usuarios');
	}

	//Notificaciones
	registrar_dispositivo(payload) {
		return axios.post(baseURL+'/notificaciones/registrar', payload);
	}

	enviar_dispositivo(payload) {
		return axios.put(baseURL+'/notificaciones/enviar', payload);
	}

	cargar_avatar() {
        return axios.get(baseURL+'/mi-cuenta/avatar',{spinner: false},{
            responseType: 'arraybuffer'
        });
    }

    subir_avatar(payload) {
    	return axios.post(baseURL+'/mi-cuenta/avatar', payload);
    }

    actualizar_identidad(payload) {
    	return axios.put(baseURL+'/mi-cuenta/identidad', payload);
    }

    enviar_otp(payload) {
        return axios.post(baseURL+'/otp',payload);
    }

    validar_otp(payload) {
        return axios.post(baseURL+'/otp/validar', payload);
    }

    crear_metodo_contacto(payload) {
        return axios.post(baseURL+'/mi-cuenta/contacto', payload)
    }

    editar_metodo_contacto(payload) {
        return axios.put(baseURL+'/mi-cuenta/contacto', payload)
    }

    eliminar_metodo_contacto(id) {
        return axios.delete(baseURL+'/mi-cuenta/contacto/'+id);
    }

    crear_metodo_contacto_general(id, payload) {
        return axios.post(baseURL+'/usuarios/'+id+'/contactos', payload)
    }

    editar_metodo_contacto_general(id, idCon, payload) {
        return axios.put(baseURL+'/usuarios/'+id+'/contactos/'+idCon, payload)
    }

    eliminar_metodo_contacto_general(id, idCon) {
        return axios.delete(baseURL+'/usuarios/'+id+'/contactos/'+idCon);
    }

    obtener_notificaciones() {
        // store.commit('set',{spinner: false});
        return axios.get(baseURL+'/mi-cuenta/notificaciones',{spinner:false});
    }

    leer_notificacion(id) {
        // store.commit('set',{spinner: false});
        return axios.put(baseURL+'/mi-cuenta/notificaciones/'+id, null, {spinner:false});
    }

    eliminar_notificacion(id) {
        return axios.delete(baseURL+'/mi-cuenta/notificaciones/'+id);
    }

    obtener_catalogo(catalogo) {
        return axios.get(`${baseURL}/catalogos/codigo/${catalogo}`);
    }

    validar_doble_auth(payload) {
        return axios.put(`${baseURL}/auth/doble_auth_verification`, payload);
    }

    activar_doble_auth_otp(payload) {
        return axios.put(`${baseURL}/mi-cuenta/doble_auth`,payload);
    }

    reenviar_doble_auth(payload) {
        return axios.put(`${baseURL}/auth/doble_auth_resend`,payload);
    }

    obtener_qr_tfa() {
        return axios.get(`${baseURL}/mi-cuenta/twofa/qrgenerator`);
    }

    validar_codigo_tfa(payload) {
        return axios.put(`${baseURL}/mi-cuenta/twofa/validation`, payload);
    }

    validar_codigo_tfa_auth(payload) {
        return axios.put(`${baseURL}/auth/twofa/validation`, payload);
    }

    deshabilitar_doble_auth() {
        return axios.put(`${baseURL}/mi-cuenta/twofa/disabled`);
    }
}

export default new Account();